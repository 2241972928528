import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../../media';
import {useParams, useNavigate} from 'react-router-dom';
import {getWpDocs, simplifyWpDate} from '../../../utilities/general';
import Layout from "../../../components/layout/main";
import Loader from "../../../components/loader/main";
import MainHeading from "../../../components/main-heading/main";
import LatestPosts from "../../../components/latest-posts/main";

export default function Post(props) {
    const [postData, setPostData] = useState(null);

    const {slug} = useParams();
    const navigate = useNavigate();

    //On component mount
    useEffect(() => {
        loadPostData();
    }, []);

    return (
        <div className="post">
            <Layout pageId={3}>
                <div className="content-block">
                    <div className="inner-content">
                        {postData===null ?
                            <div className="loader-holder">
                                <Loader />
                            </div>
                        :
                            <article id={"article-"+postData.id} className="article">
                                {(config.hasOwnProperty(postData.id) && config[postData.id].banner) && <img className="banner" src={media[config[postData.id].banner]} /> }

                                <MainHeading content={{title:postData.title.rendered, titleMaxWidth:(config.hasOwnProperty(postData.id)&&config[postData.id].titleMaxWidth)?(config[postData.id].titleMaxWidth+"px"):"none", back:"/blog"}} />
                                <span className="date">{postData.date}</span>

                                <div className="content-wrapper">
                                    <div className={"article-content"+((config.hasOwnProperty(postData.id)&&config[postData.id].contentImgs===false)?" no-imgs":"")+((config.hasOwnProperty(postData.id)&&config[postData.id].customImgs)?" has-custom":"")} style={{maxWidth:(config.hasOwnProperty(postData.id)&&config[postData.id].contentMaxWidth)?config[postData.id].contentMaxWidth+"px":"none"}} dangerouslySetInnerHTML={{__html:postData.content.rendered}}></div>

                                    {(config.hasOwnProperty(postData.id) && config[postData.id].customImgs) &&
                                        <div className="custom-imgs">
                                            {config[postData.id].customImgs.map((img, key) => {
                                                return(
                                                    <div className="img-wrapper" key={key}>
                                                        <img src={media[img.src]} />
                                                        {img.caption && <span className="caption" dangerouslySetInnerHTML={{__html:img.caption}}></span>}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                </div>
                            </article>
                        }
                    </div>
                </div>

                <div className="latest-block">
                    <div className="inner-content">
                        <LatestPosts />
                    </div>
                </div>
            </Layout>
        </div>
    )

    async function loadPostData() {
        var data = await getWpDocs("posts", {slug:slug});

        if (data.length>0) {
            data = data[0];

            data.date = simplifyWpDate(data.date);

            //Update document title
            document.title = data.title.rendered;

            //Update state
            setPostData(data);
        }
        else {
            navigate('/blog'); //Redirect to blog page if slug doesn't exist
        }
    }
}
